import {pushToDataLayer} from './pushToDataLayer';

/**
 * Envoie l'événement de liste d'articles vue au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'articles.
 * @param {string} item_list_name - Le nom de la liste d'articles.
 * @param {Array} items - Un tableau d'articles.
 */
export const pushArticlesViewItemListToDataLayer = (item_list_id, item_list_name, items) => {
    try {
        if (!Array.isArray(items)) {
            console.warn('DataLayer Invalid items:', items);
            return;
        }

        const eventParams = {
            item_list_id,
            item_list_name,
            items: items.map((item, index) => {
                if (!item || !item.id || !item.titre || !item.type || !item.slug) {
                    console.warn('DataLayer Invalid item:', item);
                    return null;
                }
                return {
                    item_id: item.id,
                    item_name: item.titre,
                    item_category: item.type,
                    index,
                    location_id: item.slug,
                    item_list_id,
                    item_list_name
                };
            }).filter(Boolean), // Supprime tous les éléments nuls
        };

        pushToDataLayer('view_item_list', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushArticlesViewItemListToDataLayer:', error);
    }
};

/**
 * Envoie l'événement de sélection d'article au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'articles.
 * @param {string} item_list_name - Le nom de la liste d'articles.
 * @param {Object} item - L'article à sélectionner.
 */
export const pushArticlesSelectItemToDataLayer = (item_list_id, item_list_name, item) => {
    try {
        if (!item || !item.id || !item.titre || !item.type || !item.slug) {
            console.warn('DataLayer Invalid item:', item);
            return;
        }

        const eventParams = {
            item_list_id,
            item_list_name,
            items: [
                {
                    item_id: item.id,
                    item_name: item.titre,
                    item_category: item.type,
                    index: 0,
                    location_id: item.slug,
                    item_list_id,
                    item_list_name
                }
            ]
        };

        pushToDataLayer('select_item', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushArticlesSelectItemToDataLayer:', error);
    }
};
