import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { LayoutContext, SCREENTYPE } from "@/hooks/useLayout";
import Publication, { VARIANT } from "@/ui/cartes/publication";
import {
    pushArticlesViewItemListToDataLayer,
    pushArticlesSelectItemToDataLayer,
} from "@/utils/dataLayer/accueil-quoiLire";

import styles from "./quoiLire.module.scss";
import type { PublicationCompact } from "@/types/publication";
import { useInView } from "react-intersection-observer";

type QuoiLireProps = {
    className?: string;
    contenus: PublicationCompact[];
};

const QuoiLire = ({ className = "", contenus }: QuoiLireProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        setIsMobile(screenType == SCREENTYPE.MOBILE);
    }, []);

    useEffect(() => {
        if (contenus && contenus.length > 0 && inView) {
            pushArticlesViewItemListToDataLayer(
                "nos-articles-coups-de-coeur",
                "Nos articles coups de coeur",
                contenus,
            );
        }
    }, [contenus, inView]);

    return (
        contenus &&
        contenus.length > 0 && (
            <section
                ref={ref}
                className={classNames(styles.me, {
                    [className]: className,
                })}
            >
                {inView && (
                    <div className="body">
                        <h2 className={styles.title}>Nos articles coups de coeur</h2>

                        <div className={styles.articlesList}>
                            {contenus &&
                                contenus.length > 0 &&
                                contenus.map((contenu) => {
                                    const { id } = contenu;

                                    return (
                                        <div
                                            key={id}
                                            className={styles.block}
                                            onClick={() => {
                                                pushArticlesSelectItemToDataLayer(
                                                    "nos-articles-coups-de-coeur",
                                                    "Nos articles coups de coeur",
                                                    contenu,
                                                );
                                            }}
                                        >
                                            <Publication
                                                publication={contenu}
                                                variant={
                                                    isMobile
                                                        ? undefined
                                                        : VARIANT.HORIZONTAL
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </section>
        )
    );
};

export default QuoiLire;
