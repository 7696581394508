import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { LayoutContext, SCREENTYPE } from "../../../hooks/useLayout";
import { path } from "../../../utils/path";
import {
    pushRepasViewItemListToDataLayer,
    pushRepasSelectItemPlanifToDataLayer,
} from "../../../utils/dataLayer/accueil-prochains-repas";

import { VARIANT } from "../../../ui/bouton";
import BoutonLink from "../../../ui/boutonLink";

import UiDesktop from "./uiDesktop";
import UiMobile from "./uiMobile";

import styles from "./quoiCuisiner.module.scss";
import { AccueilQuoiCuisiner } from "../../../types/pages/accueil";
import { Viewport } from "@/types/enum";
import { useInView } from "react-intersection-observer";

type QuoiCuisinerProps = {
    className?: string;
    contenus: AccueilQuoiCuisiner[];
    viewport: Viewport;
};

const QuoiCuisiner = ({ className = "", contenus, viewport }: QuoiCuisinerProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        setIsMobile(viewport == SCREENTYPE.MOBILE || screenType == SCREENTYPE.MOBILE);
    }, [screenType]);
    
    useEffect(() => {
        if (contenus && contenus.length > 0 && inView) {
            pushRepasViewItemListToDataLayer(
                "accueil-prochains-repas",
                "Accueil Prochains Repas",
                contenus,
            );
        }
    }, [contenus, inView]);

    const recettesLink = path.fill(path.RECETTES);

    return (
        contenus &&
        contenus.length > 0 && (
            <section
                ref={ref}
                className={classNames(styles.me, {
                    [className]: className,
                })}
            >
                {inView && (
                    <>
                        <h2 className={styles.title}>Trouvez votre prochain repas</h2>

                        <>
                            {isMobile ? (
                                <UiMobile contenus={contenus} />
                            ) : (
                                <UiDesktop contenus={contenus} />
                            )}
                        </>

                        <div className={styles.buttonLink}>
                            <BoutonLink
                                href={recettesLink}
                                variant={VARIANT.PRIMARY}
                                onClick={pushRepasSelectItemPlanifToDataLayer}
                            >
                                Planifiez votre semaine
                            </BoutonLink>
                        </div>
                    </>
                )}
            </section>
        )
    );
};

export default QuoiCuisiner;
