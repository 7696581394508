import classNames from "classnames";
import React from "react";
import styles from "./uiMobile.module.scss";

import PublicationCarte from "@/ui/cartes/publication";

import { pushArrivageSelectItemPublicationToDataLayer } from "@/utils/dataLayer/accueil-arrivage";
import { AccueilArrivages } from "@/types/pages/accueil";
import type { Publication } from "@/types/publication";

type UiMobileProps = {
    className?: string;
    contenus: AccueilArrivages;
};

const UiMobile = ({ className = "", contenus }: UiMobileProps) => {
    const { blocHaut, blocBas } = contenus;

    const { publication } = blocHaut || null;
    const { contenu } = blocBas || null;

    const publications: Publication[] = [];
    if (publication) {
        publications.push(publication);
    }

    if (contenu) {
        publications.push(contenu);
    }

    return (
        <div className={classNames(styles.me, { [className]: className }, "body")}>
            <ul>
                {publications &&
                    publications.map((article, index) => (
                        <li
                            key={article.id || index}
                            onClick={() =>
                                pushArrivageSelectItemPublicationToDataLayer(
                                    "accueil-arrivage",
                                    "Accueil Arrivage",
                                    article,
                                )
                            }
                        >
                            <PublicationCarte publication={article} />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default UiMobile;
