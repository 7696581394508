/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from "react";
import { Recette, RecetteCompact } from "@/types/recette";
import classNames from "classnames";
import Link from "next/link";

import Image from "@/ui/image";
import SliderAlmostFull from "@/ui/sliderAlmostFull";

import { path } from "@/utils/path";
import { TYPE_GABARIT } from "@/utils/enums";

import { pushRecettesSelectItemToDataLayer } from "@/utils/dataLayer/accueil-recettes-populaires";

import styles from "./uiMobile.module.scss";
import { SwiperSlide } from "swiper/react";

type UiMobileProps = {
    className?: string;
    contenus: RecetteCompact[];
};

const UiMobile = ({ className = "", contenus }: UiMobileProps) => {
    return (
        <div
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            {contenus && contenus.length > 0 && (
                <SliderAlmostFull>
                    {contenus.map((contenu, index) => {
                        if (!contenu) {
                            return null;
                        }

                        const { id, titre, images } = contenu;
                        if (!id) {
                            return null;
                        }

                        const indexDisplay = index + 1;
                        const linkTo = path.fill(path.RECETTE, contenu);

                        return (
                            <SwiperSlide
                                key={`recettesPopulaires-UiMobile${id}`}
                                style={{ width: "75%" }}
                            >
                                <div
                                    className={styles.block}
                                    onClick={() => {
                                        pushRecettesSelectItemToDataLayer(
                                            "accueil-recettes-populaires",
                                            "Accueil Recettes Populaires",
                                            contenu,
                                        );
                                    }}
                                >
                                    <div className={styles.image}>
                                        {linkTo && (
                                            (<Link href={linkTo} aria-label={titre}>

                                                <Image
                                                    className={styles.imageContenu}
                                                    images={images}
                                                    alt={titre}
                                                    gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                                                />

                                            </Link>)
                                        )}
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.index}>{indexDisplay}</span>
                                        <h3 className={styles.title}>
                                            {linkTo && (
                                                <Link href={linkTo}>
                                                    {titre}
                                                </Link>
                                            )}
                                            {!linkTo && titre}
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </SliderAlmostFull>
            )}
        </div>
    );
};

export default UiMobile;
