import classNames from "classnames";
import Link from "next/link";
import React from "react";

import { path } from "@/utils/path";
import { TYPE_GABARIT } from "@/utils/enums";
import SanitizeHTML from "@/ui/sanitizeHTML";

import BoutonLink from "@/ui/boutonLink";
import Image from "@/ui/image";
import { VARIANT } from "@/ui/bouton";

import styles from "./uiDesktop.module.scss";
import TagInTo from "@/ui/tagInTo";
import { AccueilArrivagesBlocBas } from "@/types/pages/accueil";

type BlocBasProps = {
    contenus: AccueilArrivagesBlocBas;
    className?: string;
};

const BlocBas = ({ className = "", contenus }: BlocBasProps) => {
    if (!contenus) {
        return null;
    }

    const { contenu } = contenus || null;
    if (!contenu) {
        return null;
    }

    const articleBlocBasLinkTo = path.fill(path.ARTICLE, contenu);

    return (
        <div className={classNames(styles.arrivagesBot, { [className]: className })}>
            {/* Le modele de BlocBas semble ne contenir que des publications (articles)  */}
            <TagInTo label={contenu.publicationType} />

            {contenu.images && (
                <div className={styles.bg}>
                    <Image
                        alt={contenu?.titre}
                        images={contenu.images}
                        gabarits={[TYPE_GABARIT.FORMAT_16X9]}
                        className={styles.bgImg}
                    />
                </div>
            )}
            <div className={styles.articleMain}>
                <div className={styles.articleCard}>
                    {contenu.categories.length > 0 && <p>{contenu.categories[0].nom}</p>}
                    {contenu.titre && articleBlocBasLinkTo && (
                        <h3>
                            <Link href={articleBlocBasLinkTo}>
                                {contenu.titre}
                            </Link>
                        </h3>
                    )}
                    <div className={styles.hr} />
                    {contenu.description && <SanitizeHTML html={contenu.description} tag="p" />}
                    <div className={styles.articleAuthor}>
                        <div className={styles.authorContainer}>
                            {contenu.auteur?.images && (
                                <Image
                                    ariaLabel={contenu.auteur.nom}
                                    alt={contenu.auteur.nom}
                                    images={contenu.auteur.images}
                                    gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                                    className={styles.icon}
                                />
                            )}
                            {contenu.auteur?.nom && <span>{contenu.auteur.nom}</span>}
                        </div>
                        <BoutonLink href={articleBlocBasLinkTo} size="sm" variant={VARIANT.BORDER}>
                            Lire la suite
                        </BoutonLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlocBas;
