/**
 * Envoie un événement au dataLayer.
 *
 * @param {string} eventName - Le nom de l'événement.
 * @param {Object} eventParams - Les paramètres de l'événement.
 */
export const pushToDataLayer = (eventName, eventParams) => {
    try {
        if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
            // Réinitialise l'objet ecommerce avant de pousser un nouvel événement
            window.dataLayer.push({ecommerce: null});
            // Pousse le nouvel événement au dataLayer
            window.dataLayer.push({
                event: eventName,
                ecommerce: {
                    ...eventParams
                },
            });
        } else {
            console.warn('window.dataLayer is not defined or is not an array.');
        }
    } catch (error) {
        console.error('DataLayer Error pushing to dataLayer:', error);
    }
};
