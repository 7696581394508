import classNames from "classnames";
import Link from "next/link";
import React from "react";

import { path } from "@/utils/path";
import { TYPE_GABARIT } from "@/utils/enums";

import BoutonLink from "@/ui/boutonLink";
import Etoiles from "@/ui/etoiles/etoiles";
import Image from "@/ui/image";
import { VARIANT } from "@/ui/bouton";

import styles from "./uiDesktop.module.scss";
import TagInTo from "@/ui/tagInTo";

import {
    pushArrivageSelectItemPublicationToDataLayer,
    pushArrivageSelectItemRecetteToDataLayer,
} from "@/utils/dataLayer/accueil-arrivage";
import { AccueilArrivagesBlocHaut } from "@/types/pages/accueil";

type BlocHautProps = {
    contenus: AccueilArrivagesBlocHaut;
    className?: string;
};

const BlocHaut = ({ className = "", contenus }: BlocHautProps) => {
    if (!contenus) {
        return null;
    }

    const { publication, recettes } = contenus;
    if (!publication) {
        return null;
    }

    const articleBlocHautLinkTo = path.fill(path.ARTICLE, publication);

    return (
        <div className={classNames(styles.arrivagesTop, { [className]: className })}>
            {publication.images && (
                <Image
                    alt={publication.titre || ""}
                    images={publication.images}
                    gabarits={[TYPE_GABARIT.FORMAT_16X9]}
                    className={styles.bgImg}
                />
            )}
            <article className={styles.arrivageMain}>
                <TagInTo className={styles.tag} label={publication.publicationType} />
                <div className={styles.articleContent}>
                    {publication.titre && articleBlocHautLinkTo && (
                        <h3
                            onClick={() =>
                                pushArrivageSelectItemPublicationToDataLayer(
                                    "accueil-arrivage",
                                    "Accueil Arrivage",
                                    publication,
                                )
                            }
                        >
                            <Link href={articleBlocHautLinkTo}>
                                {publication.titre}
                            </Link>
                        </h3>
                    )}
                    <div className={styles.hr} />
                    <BoutonLink
                        href={articleBlocHautLinkTo}
                        size="sm"
                        variant={VARIANT.BORDER}
                        onClick={() =>
                            pushArrivageSelectItemPublicationToDataLayer(
                                "accueil-arrivage",
                                "Accueil Arrivage",
                                publication,
                            )
                        }
                    >
                        Lire l&apos;article
                    </BoutonLink>
                </div>
            </article>
            <ul className={styles.arrivageList}>
                {recettes &&
                    recettes.map((recette, index) => {
                        // Just display 3 items on the pages
                        if (index > 2) {
                            return null;
                        }

                        const linkTo = path.fill(path.RECETTE, recette);

                        const { id, titre, images, dureeTotal, evaluations } = recette;

                        return (
                            <li key={id || index}>
                                <div
                                    className={styles.leftSide}
                                    onClick={() =>
                                        pushArrivageSelectItemRecetteToDataLayer(
                                            "accueil-arrivage",
                                            "Accueil Arrivage",
                                            recette,
                                        )
                                    }
                                >
                                    <Link href={linkTo} aria-label={titre}>

                                        <Image
                                            className={styles.imgRecette}
                                            alt={titre}
                                            images={images}
                                            gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                                        />

                                    </Link>
                                </div>
                                <div className={styles.rightSide}>
                                    <h4
                                        className={styles.rightSideTitle}
                                        onClick={() =>
                                            pushArrivageSelectItemRecetteToDataLayer(
                                                "accueil-arrivage",
                                                "Accueil Arrivage",
                                                recette,
                                            )
                                        }
                                    >
                                        {linkTo && (
                                            <Link href={linkTo}>
                                                {titre}
                                            </Link>
                                        )}
                                        {!linkTo && titre}
                                    </h4>
                                    <p>
                                        {!dureeTotal || dureeTotal === 0
                                            ? ""
                                            : `${dureeTotal} min${dureeTotal > 1 ? "s" : ""}`}
                                    </p>
                                    {evaluations.moyenne > 0 && (
                                        <Etoiles noEtoilesActives={evaluations.moyenne} />
                                    )}
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default BlocHaut;
