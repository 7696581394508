/* eslint-disable react/jsx-props-no-spreading */
import React, {ReactNode} from 'react';
import classNames from 'classnames';

import styles from './slider.module.scss';
import {Swiper} from 'swiper/react';
import {Autoplay, Navigation, Pagination, A11y} from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/a11y"
import {SwiperOptions} from 'swiper/types';

type SliderProps = {
    className?: string;
    children: ReactNode[];
    autoplay?: boolean;
    overrides?: SwiperOptions;
};  

const Slider = ({className = "", children, autoplay = false, overrides = {}}: SliderProps) => {
    
    const autoplaySettings = {
        delay: 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
    }

    const settings: SwiperOptions = {
        modules:[Autoplay, Navigation, Pagination, A11y],
        slidesPerView: 1,
        autoplay: autoplay ? autoplaySettings : false,
        speed: 1000,
        loop: true,
        navigation : true,
        pagination: {clickable: true},
        a11y: {
            enabled: true,
            prevSlideMessage: 'Diapositive précédent',
            nextSlideMessage: 'Diapositive suivant',
            firstSlideMessage: 'Premier diapositive',
            lastSlideMessage: 'Dernier diapositive',
            paginationBulletMessage: 'Aller à la diapositive {{index}}',
        }
                
    };

    return (
        <div
            data-component="Slider"
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            <Swiper
                {...settings}
                {...overrides}>
                {children}
            </Swiper>
        </div>
    );
};

export default Slider;
