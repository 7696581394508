import React, { Fragment, useState } from "react";
import classNames from "classnames";

import Bouton, { VARIANT as boutonVARIANT, SIZE as boutonSIZE } from "../../../../ui/bouton";
import Carte from "../../../../ui/cartes/carte";

import { pushRepasSelectItemToDataLayer } from "../../../../utils/dataLayer/accueil-prochains-repas";

import styles from "./uiDesktop.module.scss";
import { AccueilQuoiCuisiner } from "../../../../types/pages/accueil";

const UiDesktop = ({
    className = "",
    contenus,
}: {
    className?: string;
    contenus: AccueilQuoiCuisiner[];
}) => {
    const categoryLastSlice = contenus && contenus.length >= 1 ? contenus.slice(0) : null;
    const categoryLast =
        categoryLastSlice &&
        typeof categoryLastSlice[0] !== "undefined" &&
        typeof categoryLastSlice[0].id !== "undefined"
            ? categoryLastSlice[0].id
            : null;

    const [categoryId, setCategoryId] = useState(categoryLast);
    const changeCategoryId = (id: number) => {
        setCategoryId(id);
    };

    return (
        <div
            className={classNames(
                styles.me,
                {
                    [className]: className,
                },
                "body",
            )}
        >
            <div className={styles.categoryList}>
                <ul>
                    {contenus &&
                        contenus.length &&
                        contenus.map((contenu) => {
                            const { id, nom } = contenu;

                            if (id === categoryId) {
                                return (
                                    <li key={`cuisiner-categoryList-${id}`}>
                                        <Bouton
                                            size={boutonSIZE.XS}
                                            variant={boutonVARIANT.PRIMARY}
                                            disabled
                                        >
                                            {nom}
                                        </Bouton>
                                    </li>
                                );
                            }

                            return (
                                <li key={`cuisiner-categoryList-${id}`}>
                                    <Bouton
                                        size={boutonSIZE.XS}
                                        variant={boutonVARIANT.BORDER}
                                        onClick={changeCategoryId}
                                        onClickParam={id}
                                    >
                                        {nom}
                                    </Bouton>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <div className={styles.recettesList}>
                {contenus &&
                    contenus.length > 0 &&
                    contenus.map((contenu) => {
                        const { id, recettes } = contenu;

                        if (id !== categoryId) {
                            return null;
                        }

                        return (
                            <Fragment key={`cuisiner-recettesList-${id}`}>
                                {recettes &&
                                    recettes.map((recette) => {
                                        const { id: recetteId } = recette;
                                        return (
                                            <div
                                                key={recetteId}
                                                className={styles.block}
                                                onClick={() => {
                                                    pushRepasSelectItemToDataLayer(
                                                        "accueil-prochains-repas",
                                                        "Accueil Prochains Repas",
                                                        recette,
                                                        contenu.nom,
                                                    );
                                                }}
                                            >
                                                <Carte key={recetteId} contenu={recette} />
                                            </div>
                                        );
                                    })}
                            </Fragment>
                        );
                    })}
            </div>
        </div>
    );
};

export default UiDesktop;
