/* eslint-disable max-len */
import React from "react";
import classNames from "classnames";

import Carte from "@/ui/cartes/carte";

import { pushEmissionSelectItemToDataLayer } from "@/utils/dataLayer/accueil-emissions-recentes";

import styles from "./uiDesktop.module.scss";
import { AccueilRecent } from "@/types/pages/accueil";

type UiDesktopProps = {
    className?: string;
    contenus: AccueilRecent[];
};

const UiDesktop = ({ className = "", contenus }: UiDesktopProps) => {
    return (
        <div
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            {contenus.map((contenu) => {
                const { id } = contenu;
                return (
                    <div
                        key={id}
                        className={styles.block}
                        onClick={() => {
                            pushEmissionSelectItemToDataLayer(
                                "accueil-emissions-recentes",
                                "Accueil Emissions Recentes",
                                contenu,
                            );
                        }}
                    >
                        <Carte contenu={contenu} isContenuRecent />
                    </div>
                );
            })}
        </div>
    );
};

export default UiDesktop;
