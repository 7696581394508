import {pushToDataLayer} from './pushToDataLayer';

/**
 * Envoie l'événement de liste d'éléments vue pour les repas au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @param {Array} items - Un tableau d'éléments de repas.
 */
export const pushRepasViewItemListToDataLayer = (item_list_id, item_list_name, items) => {
    try {
        if (!Array.isArray(items)) {
            console.warn('DataLayer Invalid items:', items);
            return;
        }

        let allRecettes = [];
        items.forEach(item => {
            if (item.recettes && Array.isArray(item.recettes)) {
                const recettesWithNom = item.recettes.map(recette => {
                    if (!recette || !recette.id || !recette.titre || !recette.type || !recette.slug) {
                        console.warn('DataLayer Invalid recette:', recette);
                        return null;
                    }
                    return {
                        ...recette,
                        nom: item.nom
                    };
                }).filter(Boolean); // Supprime tous les éléments nuls
                allRecettes = allRecettes.concat(recettesWithNom);
            }
        });

        const eventParams = {
            item_list_id,
            item_list_name,
            items: allRecettes.map((el, index) => ({
                item_id: el.id,
                item_name: el.titre,
                item_category: el.type,
                index,
                location_id: el.slug,
                variant: el.nom,
                item_list_id,
                item_list_name
            }))
        };

        pushToDataLayer('view_item_list', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushRepasViewItemListToDataLayer:', error);
    }
};

/**
 * Envoie l'événement de sélection d'élément pour un repas au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @param {Object} item - L'élément à sélectionner.
 * @param {string} nom - Le nom de la recette.
 */
export const pushRepasSelectItemToDataLayer = (item_list_id, item_list_name, item, nom) => {
    try {
        if (!item || !item.id || !item.titre || !item.type || !item.slug) {
            console.warn('DataLayer Invalid item:', item);
            return;
        }

        const eventParams = {
            item_list_id,
            item_list_name,
            items: [
                {
                    item_id: item.id,
                    item_name: item.titre,
                    item_category: item.type,
                    index: 0,
                    location_id: item.slug,
                    variant: nom,
                    item_list_id,
                    item_list_name
                }
            ]
        };

        pushToDataLayer('select_item', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushRepasSelectItemToDataLayer:', error);
    }
};

/**
 * Envoie l'événement de sélection d'élément pour planifier la semaine au dataLayer.
 */
export const pushRepasSelectItemPlanifToDataLayer = () => {
    try {
        const item_list_id = 'accueil-prochains-repas';
        const item_list_name = 'Accueil Prochains Repas';
        const eventParams = {
            item_list_id,
            item_list_name,
            items: [
                {
                    item_id: 'planifiez-votre-semaine',
                    item_name: 'Planifiez votre semaine',
                    item_category: 'Button',
                    index: 0,
                    item_list_id,
                    item_list_name
                }
            ]
        };

        pushToDataLayer('select_item', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushRepasSelectItemPlanifToDataLayer:', error);
    }
};
