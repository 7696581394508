import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Image from "@/ui/image";

import styles from "./slide.module.scss";
import BoutonLink from "../../../ui/boutonLink";
import { LayoutContext, SCREENTYPE } from "../../../hooks/useLayout";
import { TYPE_CONTENU, TYPE_GABARIT } from "../../../utils/enums";
import TagInTo from "@/ui/tagInTo";
import { path } from "@/utils/path";
import { isUrlExternal, stripToInternalUrl } from "@/utils/urls";
import SanitizeHTML from "@/ui/sanitizeHTML";
import { pushCarrouselSelectItemToDataLayer } from "@/utils/dataLayer/accueil-carrousel";
import { Viewport } from "@/types/enum";
import { AccueilCarousel } from "@/types/pages/accueil";

type SlideProps = {
    className?: string;
    data: AccueilCarousel;
    showSafeZone?: boolean;
    viewport?: Viewport;
};

const Slide = ({ className = "", data, showSafeZone, viewport }: SlideProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);

    const {
        alignement,
        couleur,
        description,
        fond,
        entiteId,
        imageDesktop,
        imageMobile,
        lien,
        slug,
        texteLien,
        titre,
        titreMobile,
        typeEntite,
        carouselLabel,
    } = data;

    const txtColor = couleur === "blanc" ? "#fff" : "#000";
    const lienTexte = texteLien || "Voir plus";

    const onClickPushDataLayer = () => {
        pushCarrouselSelectItemToDataLayer("accueil-carrousel", "Accueil Carrousel", data);
    };

    // Returns a 404 if data is false or non-existent

    const url =
        stripToInternalUrl(lien) ||
        path.fill(typeEntite === TYPE_CONTENU.RECETTE ? path.RECETTE : path.ARTICLE, {
            id: entiteId,
            slug,
        });

    const titreCarouselMobile = titreMobile || titre || null;
    const imagesDesktopUrl =
        screenType === SCREENTYPE.DESKTOP &&
        imageDesktop?.declinaisons &&
        imageDesktop?.declinaisons.length > 0
            ? `${imageDesktop?.path}/${imageDesktop?.declinaisons[0].file}`
            : "";

    useEffect(() => {
        setIsMobile(viewport == SCREENTYPE.MOBILE || screenType == SCREENTYPE.MOBILE);
    }, [screenType]);

    return isMobile ? (
        <div
            className={classNames(styles.meMobile, {
                [className]: className,
            })}
        >
            {imageMobile && (
                <Image
                    className={styles.img}
                    images={[imageMobile]}
                    gabarits={[TYPE_GABARIT.FORMAT_16X9]}
                    alt={titre}
                />
            )}
            {titreCarouselMobile && (
                <>
                    <div className={styles.txtZone}>
                        <SanitizeHTML html={titreCarouselMobile} tag="h2" />
                    </div>
                </>
            )}
            {url && (
                <div className={styles.cta}>
                    <BoutonLink
                        href={url}
                        target={isUrlExternal(url) ? "_blank" : "_self"}
                        onClick={onClickPushDataLayer}
                    >
                        {lienTexte}
                    </BoutonLink>
                </div>
            )}
        </div>
    ) : (
        <div
            className={classNames(styles.meDesktop, {
                [className]: className,
                [styles[`template_${alignement}`]]: true,
            })}
            style={{ backgroundImage: `url("${imagesDesktopUrl}")` }}
            data-imagesurl={imagesDesktopUrl}
        >
            <div
                className={classNames(styles.txtZone, {
                    [styles.showSafeZone]: showSafeZone,
                    [styles.block]: fond === "opaque",
                })}
                style={{ color: txtColor }}
            >
                {carouselLabel && <TagInTo className={styles.tag} label={carouselLabel} />}
                {titre && (
                    <>
                        <SanitizeHTML html={titre} tag="h2" />
                        <hr />
                    </>
                )}
                {description && <SanitizeHTML html={description} tag="p" />}
                {url && (
                    <div className={styles.cta}>
                        <BoutonLink
                            href={url}
                            target={isUrlExternal(url) ? "_blank" : "_self"}
                            onClick={onClickPushDataLayer}
                        >
                            {lienTexte}
                        </BoutonLink>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Slide;
