import React, { useEffect } from "react";
import classNames from "classnames";

import Slider from "../../../ui/slider";
import Slide from "./slide";

import styles from "./carrousel.module.scss";
import { pushCarrouselViewItemListToDataLayer } from "../../../utils/dataLayer/accueil-carrousel";
import { SwiperSlide } from "swiper/react";
import { Viewport } from "@/types/enum";
import { AccueilCarousel } from "@/types/pages/accueil";

type CarouselProps = {
    contenus: AccueilCarousel[];
    viewport: Viewport;
};

const Carrousel = ({ contenus, viewport }: CarouselProps) => {
    useEffect(() => {
        if (contenus && contenus.length > 0) {
            pushCarrouselViewItemListToDataLayer(
                "accueil-carrousel",
                "Accueil Carrousel",
                contenus,
            );
        }
    }, [contenus]);

    return (
        contenus &&
        contenus.length > 0 && (
            <Slider className={classNames("body", styles.slider)} autoplay>
                {contenus?.map((contenu) => (
                    <SwiperSlide key={`${contenu.typeEntite}_${contenu.slug}`}>
                        <Slide data={contenu} viewport={viewport} />
                    </SwiperSlide>
                ))}
            </Slider>
        )
    );
};

export default Carrousel;
