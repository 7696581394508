import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { LayoutContext, SCREENTYPE } from "../../hooks/useLayout";

import styles from "./infolettre.module.scss";
import { VARIANT } from "../bouton";
import BoutonLink from "../boutonLink";
import URLS from "../../configs/urls";
import Icon from "../icon";

type PropTypes = {
    className?: string;
    isSmall?: boolean;
};

const InfolettreComponent = ({ className, isSmall = false }: PropTypes) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        setIsMobile(screenType == SCREENTYPE.MOBILE);
    }, []);

    return (
        <div data-component="InfolettreComponent" className={classNames(styles.me, className)}>
            <div className={styles.baniere}>
                <div
                    className={classNames("body", styles.container, {
                        [styles.small]: isMobile || isSmall,
                    })}
                >
                    {!isSmall && <Icon icon="courriel_cercle" />}
                    {(isMobile || isSmall) && (
                        <div className={styles.content}>
                            <h3 className={styles.title}>Encore plus d&apos;idées de repas?</h3>
                            <p>Abonnez-vous à notre infolettre!</p>
                        </div>
                    )}
                    {!isMobile && !isSmall && (
                        <div className={styles.content}>
                            <h3 className={styles.title}>
                                L&apos;ingrédient secret est dans votre boîte courriel{" "}
                            </h3>
                            <p>Abonnez-vous à notre infolettre!</p>
                            <p>Même votre boîte de réception a hâte.</p>
                        </div>
                    )}
                    <div>
                        <BoutonLink
                            href={URLS.infolettre}
                            rel="noreferrer"
                            target="_blank"
                            variant={VARIANT.PRIMARY}
                        >
                            Je m&apos;abonne
                        </BoutonLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfolettreComponent;
