import { pushToDataLayer } from './pushToDataLayer';

/**
 * Formate les éléments de publication pour le dataLayer.
 *
 * @param {Object} item - L'élément de publication.
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @returns {Array} - Un tableau contenant un élément de publication formaté, ou un tableau vide si l'entrée est invalide.
 */
const getPublicationItems = (item, item_list_id, item_list_name) => {
    if (!item || !item.id || !item.titre || !item.type || !item.slug) {
        console.warn('DataLayer Invalid item:', item);
        return [];
    }
    return [{
        item_id: item.id,
        item_name: item.titre,
        item_category: item.type,
        index: 0,
        location_id: item.slug,
        item_list_id,
        item_list_name
    }];
};

/**
 * Formate les éléments de recette pour le dataLayer.
 *
 * @param {Array} recettes - Un tableau d'éléments de recette.
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @returns {Array} - Un tableau d'éléments de recette formatés, ou un tableau vide si l'entrée est invalide.
 */
const getRecettesItems = (recettes, item_list_id, item_list_name) => {
    if (!Array.isArray(recettes)) {
        console.warn('DataLayer Invalid recettes:', recettes);
        return [];
    }
    return recettes.map((item, index) => {
        if (!item || !item.id || !item.titre || !item.type || !item.slug) {
            console.warn('DataLayer Invalid item in recettes:', item);
            return null;
        }
        return {
            item_id: item.id,
            item_name: item.titre,
            item_category: item.type,
            index: index + 1,
            location_id: item.slug,
            item_list_id,
            item_list_name
        };
    }).filter(Boolean); // Supprime tous les éléments nuls
};

/**
 * Envoie l'événement de liste d'éléments vue au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @param {Object} contenus - Les contenus à envoyer, contenant publication et recettes.
 */
export const pushArrivagesViewItemListToDataLayer = (item_list_id, item_list_name, contenus) => {
    try {
        if (!contenus || !contenus.blocHaut) {
            console.warn('DataLayer Invalid contenus:', contenus);
            return;
        }
        let items = getPublicationItems(contenus.blocHaut.publication, item_list_id, item_list_name);
        items = items.concat(getRecettesItems(contenus.blocHaut.recettes, item_list_id, item_list_name));
        const eventParams = {
            item_list_id,
            item_list_name,
            items
        };

        pushToDataLayer('view_item_list', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushArrivagesViewItemListToDataLayer:', error);
    }
};

/**
 * Envoie l'événement de sélection d'élément pour une publication au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @param {Object} item - L'élément à sélectionner.
 */
export const pushArrivageSelectItemPublicationToDataLayer = (item_list_id, item_list_name, item) => {
    try {
        if (!item || !item.id || !item.titre || !item.type || !item.slug) {
            console.warn('Invalid item:', item);
            return;
        }
        const eventParams = {
            item_list_id,
            item_list_name,
            items: [{
                item_id: item.id,
                item_name: item.titre,
                item_category: item.type,
                index: 0,
                location_id: item.slug,
                item_list_id,
                item_list_name
            }]
        };

        pushToDataLayer('select_item', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushArrivageSelectItemPublicationToDataLayer:', error);
    }
};

/**
 * Envoie l'événement de sélection d'élément pour une recette au dataLayer.
 *
 * @param {string} item_list_id - L'ID de la liste d'éléments.
 * @param {string} item_list_name - Le nom de la liste d'éléments.
 * @param {Object} item - L'élément à sélectionner.
 */
export const pushArrivageSelectItemRecetteToDataLayer = (item_list_id, item_list_name, item) => {
    try {
        if (!item || !item.id || !item.titre || !item.type || !item.slug) {
            console.warn('Invalid item:', item);
            return;
        }
        const eventParams = {
            item_list_id,
            item_list_name,
            items: [{
                item_id: item.id,
                item_name: item.titre,
                item_category: item.type,
                index: 0,
                location_id: item.slug,
                item_list_id,
                item_list_name
            }]
        };

        pushToDataLayer('select_item', eventParams);
    } catch (error) {
        console.error('DataLayer Error in pushArrivageSelectItemRecetteToDataLayer:', error);
    }
};
