/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from "react";
import classNames from "classnames";

import SliderAlmostFull from "../../../../ui/sliderAlmostFull";
import Carte from "../../../../ui/cartes/carte";
import { pushEmissionSelectItemToDataLayer } from "../../../../utils/dataLayer/accueil-emissions-recentes";

import styles from "./uiMobile.module.scss";
import { SwiperSlide } from "swiper/react";
import { AccueilRecent } from "@/types/pages/accueil";

type UiMobileProps = {
    className?: string;
    contenus: AccueilRecent[];
};

const UiMobile = ({ className = "", contenus }: UiMobileProps) => {
    return (
        <div
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            <SliderAlmostFull>
                {contenus.map((contenu) => {
                    const { id } = contenu;
                    return (
                        <SwiperSlide key={`contentUiMobile-${id}`}>
                            <div
                                className={styles.block}
                                onClick={() => {
                                    pushEmissionSelectItemToDataLayer(
                                        "accueil-emissions-recentes",
                                        "Accueil Emissions Recentes",
                                        contenu,
                                    );
                                }}
                            >
                                <Carte contenu={contenu} isContenuRecent />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </SliderAlmostFull>
        </div>
    );
};

export default UiMobile;
