import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { LayoutContext, SCREENTYPE } from "@/hooks/useLayout";

import UiDesktop from "./uiDesktop";
import UiMobile from "./uiMobile";

import styles from "./recettesPopulaires.module.scss";

import { pushRecettesViewItemListToDataLayer } from "@/utils/dataLayer/accueil-recettes-populaires";
import { RecetteCompact } from "@/types/recette";
import { useInView } from "react-intersection-observer";

type RecettesPopulairesProps = {
    className?: string;
    contenus: RecetteCompact[];
    viewport: string;
};

const RecettesPopulaires = ({ className = "", contenus, viewport }: RecettesPopulairesProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        setIsMobile(viewport == SCREENTYPE.MOBILE || screenType == SCREENTYPE.MOBILE);
    }, [screenType]);

    useEffect(() => {
        if (contenus && contenus.length > 0 && inView) {
            pushRecettesViewItemListToDataLayer(
                "accueil-recettes-populaires",
                "Accueil Recettes Populaires",
                contenus,
            );
        }
    }, [contenus, inView]);

    return (
        contenus &&
        contenus.length > 0 && (
            <section
                ref={ref}
                id="main-content"
                className={classNames(
                    styles.me,
                    {
                        [className]: className,
                    },
                    "body",
                )}
            >
                <h2 className={styles.title}>Vos recettes préférées</h2>

                <>
                    {isMobile ? (
                        <UiMobile contenus={contenus} />
                    ) : (
                        <UiDesktop contenus={contenus} />
                    )}
                </>
            </section>
        )
    );
};

export default RecettesPopulaires;
