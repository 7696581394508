import React from "react";
import classNames from "classnames";
import InfolettreComponent from "../../../ui/pub/infolettre";

import styles from "./infolettre.module.scss";
import { useInView } from "react-intersection-observer";

type InfolettreProps = {
    className?: string;
};

const Infolettre = ({ className = "" }: InfolettreProps) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    return (
        <section
            ref={ref}
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            {inView && <InfolettreComponent />}
        </section>
    );
};

export default Infolettre;
