"use client";
import React from "react";
import Select from "react-select";
import { Theme } from "@/types/components/select";

const SelectForm = (props: any = {}) => {
    const customSelectStyles = {
        dropdownIndicator: (provided: {}) => ({
            ...provided,
            color: "#272727",
            fontSize: "12px",
            width: "32px",
        }),
        control: (provided: {}) => ({
            ...provided,
            backgroundColor: "#e5e5e5",
            border: "0 none",
            color: "#272727",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "600",
            paddingLeft: "2px",
        }),
        indicatorSeparator: (provided: {}) => ({
            ...provided,
            backgroundColor: "transparent",
            border: "0 none",
            width: "0",
        }),
        option: (provided: {}) => ({
            ...provided,
            cursor: "pointer",
        }),
    };

    return (
        <Select
            styles={customSelectStyles}
            theme={(theme: Theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: "#005b5f",
                },
            })}
            aria-label="Sélectionner"
            {...props}
        />
    );
};

export default SelectForm;
