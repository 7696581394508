import React from "react";
import classNames from "classnames";
import BlocHaut from "./blocHaut";
import BlocBas from "./blocBas";

import styles from "./uiDesktop.module.scss";
import { AccueilArrivages } from "@/types/pages/accueil";

type UiDesktopProps = {
    className?: string;
    contenus: AccueilArrivages;
};

const UiDesktop = ({ className = "", contenus }: UiDesktopProps) => {
    const { blocHaut, blocBas } = contenus;

    return (
        <div className={classNames(styles.me, { [className]: className }, "body")}>
            <BlocHaut contenus={blocHaut} />
            <BlocBas contenus={blocBas} />
        </div>
    );
};

export default UiDesktop;
