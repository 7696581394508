import React from 'react';

import styles from './sliderAlmostFull.module.scss';
import {Swiper} from 'swiper/react';
import {Pagination, A11y} from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/a11y"

import {SwiperOptions} from 'swiper/types';

const SliderAlmostFull = ({children}: {children: React.ReactNode}) => {
    const settings: SwiperOptions = {
        modules:[Pagination, A11y],
        slidesPerView: 1.2,
        speed:1000,
        pagination: {clickable: true},
        a11y: {
            enabled: true,
            prevSlideMessage: 'Diapositive précédent',
            nextSlideMessage: 'Diapositive suivant',
            firstSlideMessage: 'Premier diapositive',
            lastSlideMessage: 'Dernier diapositive',
            paginationBulletMessage: 'Aller à la diapositive {{index}}',
        }
                
    };

    return (
        <div
            data-component="SliderAlmostFull"
            className={styles.me}
        >
            <Swiper {...settings}>{children}</Swiper>
        </div>
    );
};

export default SliderAlmostFull;
