import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./arrivages.module.scss";
import { LayoutContext, SCREENTYPE } from "../../../hooks/useLayout";
import UiMobile from "./uiMobile";
import UiDesktop from "./uiDesktop";
import { pushArrivagesViewItemListToDataLayer } from "../../../utils/dataLayer/accueil-arrivage";
import { AccueilArrivages } from "../../../types/pages/accueil";
import { useInView } from "react-intersection-observer";

type ArrivagesProps = {
    className?: string;
    contenus: AccueilArrivages;
    viewport: "mobile" | "desktop";
};

const Arrivages = ({ className = "", contenus, viewport }: ArrivagesProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        setIsMobile(viewport == SCREENTYPE.MOBILE || screenType == SCREENTYPE.MOBILE);
    }, [screenType]);

    useEffect(() => {
        if (
            (contenus?.blocBas?.contenu ||
                contenus?.blocHaut?.publication ||
                contenus?.blocHaut?.recettes.length > 0) &&
            inView
        ) {
            pushArrivagesViewItemListToDataLayer("accueil-arrivage", "Accueil Arrivage", contenus);
        }
    }, [contenus, inView]);

    return (
        (contenus?.blocBas?.contenu ||
            contenus?.blocHaut?.publication ||
            contenus?.blocHaut?.recettes.length > 0) && (
            <section
                ref={ref}
                className={classNames(styles.me, {
                    [className]: className,
                })}
            >
                {inView && (
                    <>
                        <h2 className={styles.title}>
                            {contenus?.blocHaut?.titre
                                ? contenus.blocHaut.titre
                                : "La récolte de la semaine"}
                        </h2>

                        {isMobile ? (
                            <UiMobile contenus={contenus} />
                        ) : (
                            <UiDesktop contenus={contenus} />
                        )}
                    </>
                )}
            </section>
        )
    );
};

export default Arrivages;
