import React from "react";
import classNames from "classnames";
import Link from "next/link";

import Image from "@/ui/image";

import { path } from "@/utils/path";
import { RecetteCompact } from "@/types/recette";
import { TYPE_GABARIT } from "@/utils/enums";

import styles from "./uiDesktop.module.scss";
import { pushRecettesSelectItemToDataLayer } from "@/utils/dataLayer/accueil-recettes-populaires";

type UiDesktopProps = {
    className?: string;
    contenus: RecetteCompact[];
};

const UiDesktop = ({ className = "", contenus }: UiDesktopProps) => (
    <div
        className={classNames(styles.me, {
            [className]: className,
        })}
    >
        {contenus && contenus.length > 0 && (
            contenus.map((contenu, index) => {
                const { id, titre, images } = contenu;
                const indexDisplay = index + 1;

                const linkTo = path.fill(path.RECETTE, contenu);
                return (
                    <div
                        key={id}
                        className={styles.block}
                        onClick={() => {
                            pushRecettesSelectItemToDataLayer(
                                "accueil-recettes-populaires",
                                "Accueil Recettes Populaires",
                                contenu,
                            );
                        }}
                    >
                        <div className={styles.image}>
                            {linkTo && (
                                (<Link href={linkTo}>

                                    <Image
                                        className={styles.imageContenu}
                                        images={images}
                                        alt={titre}
                                        gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                                    />

                                </Link>)
                            )}
                        </div>
                        <div className={styles.content}>
                            <span className={styles.index}>{indexDisplay}</span>
                            <h3 className={styles.title}>
                                {linkTo && (
                                    <Link href={linkTo}>
                                        {titre}
                                    </Link>
                                )}
                            </h3>
                        </div>
                    </div>
                );
            })
        )}
    </div>
);

export default UiDesktop;
